import React from 'react';
import PropTypes from 'prop-types';
import { Box, Slider } from '@mui/material';
import TextField from './TextField';
import { getCheckInRangeFunc } from '../../../../validation';
import additionalFields from '../../../utils/additionalFields';

const PeakShavingMode = (props) => {
  const { formData, change, valid, pristine } = props;
  const [sliderValue, setSliderValue] = React.useState([formData?.peakShavingSocDischargeLimit, formData?.peakShavingSocMaxLimit]);

  React.useEffect(() => {
    if (sliderValue[0] !== formData?.peakShavingSocDischargeLimit) {
      change('peakShavingSocDischargeLimit', sliderValue[0]);
    }
    if (sliderValue[1] !== formData?.peakShavingSocMaxLimit) {
      change('peakShavingSocMaxLimit', sliderValue[1]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sliderValue]);

  React.useEffect(() => {
    if (
      formData && (valid || pristine)
      && (formData?.peakShavingSocDischargeLimit !== sliderValue[0]
        || formData?.peakShavingSocMaxLimit !== sliderValue[1])
    ) {
      setSliderValue([formData.peakShavingSocDischargeLimit, formData.peakShavingSocMaxLimit]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const peakShavingSocDischargeLimitRangeValidation = React.useMemo(
    () => getCheckInRangeFunc(0, sliderValue[1]),
    [sliderValue]
  );
  const peakShavingSocMaxLimitRangeValidation = React.useMemo(
    () => getCheckInRangeFunc(sliderValue[0], 100),
    [sliderValue]
  );

  return (
    <>
      <Slider
        sx={{
          marginBottom: '36px',
          '& .MuiSlider-thumb': {
            color: '#fff'
          },
          '& .MuiSlider-rail': {
            background: `linear-gradient(to right, #D6D8D5, #D6D8D5 ${sliderValue[0]}%, #5BC35F ${sliderValue[0]}%, #5BC35F ${sliderValue[1]}%, #FFFFFF1A ${sliderValue[1]}%, #FFFFFF1A 100%)`,
            opacity: 1
          },
          '& .MuiSlider-valueLabel': {
            backgroundColor: 'unset',
            top: 0,
            color: 'text.primary',
            opacity: 0.5
          },
          '& .MuiSlider-markLabel': {
            color: 'text.primary',
            opacity: 0.5
          },
          '& .MuiSlider-mark': {
            backgroundColor: 'unset'
          }
        }}
        track={false}
        aria-labelledby="track-false-range-slider"
        valueLabelDisplay="on"
        valueLabelFormat={(value) => `${value}%`}
        defaultValue={sliderValue}
        value={sliderValue}
        min={0}
        max={100}
        disableSwap
        onChange={(event, value) => setSliderValue(value)}
        marks={[0, 20, 40, 60, 80, 100].map((value) => ({
          value,
          label: `${value}%`
        }))}
      />
      <Box className="field-wrapper" sx={{ marginBottom: '36px' }}>
        <TextField
          {...additionalFields.peakShavingSocDischargeLimit}
          name="peakShavingSocDischargeLimit"
          validate={[...additionalFields.peakShavingSocDischargeLimit.validate, peakShavingSocDischargeLimitRangeValidation]}
        />
        <TextField
          {...additionalFields.peakShavingSocMaxLimit}
          name="peakShavingSocMaxLimit"
          validate={[...additionalFields.peakShavingSocMaxLimit.validate, peakShavingSocMaxLimitRangeValidation]}
          labelColor="#5BC35F"
        />
      </Box>
      <Box className="field-wrapper">
        <TextField
          {...additionalFields.peakShavingMaxGridPower}
          name="peakShavingMaxGridPower"
        />
        <TextField
          {...additionalFields.peakShavingRechargePower}
          name="peakShavingRechargePower"
        />
      </Box>
    </>
  );
};

PeakShavingMode.propTypes = {
  formData: PropTypes.instanceOf(Object),
  change: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired
};

export default PeakShavingMode;
