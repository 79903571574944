import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { change, getFormValues, reduxForm } from 'redux-form';
import { Typography } from '@mui/material';

import ModalWindow from '../../../ModalWindow';
import { CancelButton, PrimaryButton } from '../../../../components/UIKit';
import { getBatteryModeModalSelector } from '../../../../redux-store/selectors/modals';
import { closeModalWindow, BATTERY_MODE_MODAL_ID } from '../../../ModalWindow/slice';
import { addDeviceFormId, editDeviceFormId } from '../../constants';
import TariffOptimizedMode from './components/TariffOptimizedMode';
import PeakShavingMode from './components/PeakShavingMode';
import ManualMode from './components/ManualMode';
import EcoMode from './components/EcoMode';
import i18n from '../../../../i18n';
import './index.scss';

function isNumber(value) {
  return typeof value === 'number';
}

const currentFormID = 'batteryModeForm';

const BatteryModeModalWindow = (props) => {
  const { change: changeFromProps, invalid, valid, reset, initialize, pristine } = props;
  const dispatch = useDispatch();
  const modal = useSelector(getBatteryModeModalSelector);
  const mode = modal?.data?.mode;
  const switchIndex = modal?.data?.switchIndex;
  const parentFormID = mode === 'add' ? addDeviceFormId : editDeviceFormId;
  const parentFormData = useSelector(getFormValues(parentFormID));
  const currentFormData = useSelector(getFormValues(currentFormID));

  React.useEffect(() => {
    if (modal?.opened) {
      switch (modal?.data?.batteryMode) {
        case 1: {
          initialize({
            dischargeSocLimit: isNumber(switchIndex)
              ? parentFormData.data.switchingTimes[switchIndex].dischargeSocLimit
              : parentFormData.data.dischargeSocLimit,
            morningSocLimit: isNumber(switchIndex)
              ? parentFormData.data.switchingTimes[switchIndex].morningSocLimit
              : parentFormData.data.morningSocLimit,
            chargingSocLimit: isNumber(switchIndex)
              ? parentFormData.data.switchingTimes[switchIndex].chargingSocLimit
              : parentFormData.data.chargingSocLimit
          });
          break;
        }
        case 2: {
          initialize({
            peakShavingSocDischargeLimit: isNumber(switchIndex)
              ? parentFormData.data.switchingTimes[switchIndex].peakShavingSocDischargeLimit
              : parentFormData.data.peakShavingSocDischargeLimit,
            peakShavingSocMaxLimit: isNumber(switchIndex)
              ? parentFormData.data.switchingTimes[switchIndex].peakShavingSocMaxLimit
              : parentFormData.data.peakShavingSocMaxLimit,
            peakShavingMaxGridPower: isNumber(switchIndex)
              ? parentFormData.data.switchingTimes[switchIndex].peakShavingMaxGridPower
              : parentFormData.data.peakShavingMaxGridPower,
            peakShavingRechargePower: isNumber(switchIndex)
              ? parentFormData.data.switchingTimes[switchIndex].peakShavingRechargePower
              : parentFormData.data.peakShavingRechargePower
          });
          break;
        }
        case 3: {
          initialize({
            batteryManualMode: isNumber(switchIndex)
              ? parentFormData.data.switchingTimes[switchIndex].batteryManualMode
              : parentFormData.data.batteryManualMode,
            lowerSocLimit: isNumber(switchIndex)
              ? parentFormData.data.switchingTimes[switchIndex].lowerSocLimit
              : parentFormData.data.lowerSocLimit,
            upperSocLimit: isNumber(switchIndex)
              ? parentFormData.data.switchingTimes[switchIndex].upperSocLimit
              : parentFormData.data.upperSocLimit
          });
          break;
        }
        case 4: {
          initialize({
            tariffPriceLimit: isNumber(switchIndex)
              ? parentFormData.data.switchingTimes[switchIndex].tariffPriceLimit
              : parentFormData.data.tariffPriceLimit
          });
          break;
        }
        default:
          break;
      }
    } else {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened]);

  const closeModal = () => dispatch(closeModalWindow({ modalID: BATTERY_MODE_MODAL_ID }));
  const onSave = () => {
    Object.keys(currentFormData).forEach((key) => {
      if (Object.hasOwn(currentFormData, key)) {
        let fieldName = 'data.';
        if (isNumber(switchIndex)) {
          fieldName += `switchingTimes[${switchIndex}].`;
        }
        fieldName += key;

        dispatch(change(parentFormID, fieldName, currentFormData[key]));
      }
    });

    closeModal();
  };

  const componentProps = {
    formData: currentFormData,
    change: changeFromProps,
    valid,
    pristine
  };

  const batteryModes = {
    1: { title: 'ecoMode', description: 'ecoModeDesc', component: <EcoMode {...componentProps} /> },
    2: {
      title: 'peakShavingMode',
      description: 'peakShavingModeDesc',
      component: <PeakShavingMode {...componentProps} />
    },
    3: {
      title: 'manualMode',
      description: 'manualModeDesc',
      component: <ManualMode {...componentProps} />
    },
    4: {
      title: 'tariffOptimizedMode',
      description: 'tariffOptimizedModeDesc',
      component: <TariffOptimizedMode {...componentProps} />
    }
  };

  const currentMode = batteryModes[modal?.data?.batteryMode];

  return (
    <ModalWindow isModalOver modalID={BATTERY_MODE_MODAL_ID}>
      <div className="modal-header">
        <Typography className="modal-title" variant="h3" sx={{ color: 'text.primary' }}>
          {i18n.t(currentMode?.title)}
        </Typography>
      </div>
      <div className="modal-body">
        <div className="description-wrapper">
          <Typography
            variant="h4"
            sx={{ color: 'text.primary', marginBottom: '12px', lineHeight: '24px' }}
          >
            {i18n.t('descriptionAndSettings')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.primary', lineHeight: '18px' }}>
            {i18n.t(currentMode?.description)}
          </Typography>
        </div>
        {currentMode?.component}
      </div>
      <div className="modal-footer">
        <CancelButton onClickHandler={closeModal} />
        <PrimaryButton onClickHandler={onSave} disabled={invalid} />
      </div>
    </ModalWindow>
  );
};

BatteryModeModalWindow.propTypes = {
  change: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired
};

const form = reduxForm({
  form: currentFormID
})(BatteryModeModalWindow);

export default form;
