import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, Slider, Typography } from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import i18n from '../../../../../i18n';
import { useGetDynamicPricesDataQuery } from '../../../services';
import { getUserDevicesUser_IdSelector } from '../../../../../redux-store/selectors/userDevices';

const TariffOptimizedMode = (props) => {
  const { formData, change } = props;
  const [sliderValue, setSliderValue] = React.useState([formData?.tariffPriceLimit || 0.2]);
  const userId = useSelector(getUserDevicesUser_IdSelector);
  const { data } = useGetDynamicPricesDataQuery({ userId });
  const maxPrice = Math.max(...(data?.prices || []).map((price) => price[1])) + 0.02;

  React.useEffect(() => {
    if (sliderValue[0] !== formData?.tariffPriceLimit) {
      change('tariffPriceLimit', sliderValue[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sliderValue]);

  return (
    <>
      <Box sx={{ marginTop: '-12px', marginBottom: '24px' }}>
        <Typography variant="h4" sx={{ color: 'text.primary', lineHeight: '24px' }}>
          {i18n.t('priceSignalAt')}
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: 'text.primary', opacity: 0.5, lineHeight: '16px' }}
        >
          9
          {' '}
          {i18n.t('hours')}
          , 99.4 kWh
          {' '}
          {i18n.t('for')}
          {' '}
          Ø 6.2 ct/kWh
        </Typography>
      </Box>
      <Box sx={{ marginBottom: '24px' }}>
        <Typography
          variant="body2"
          sx={{
            color: 'text.primary',
            opacity: 0.5,
            fontSize: '10px',
            lineHeight: '12px',
            fontWeight: 500,
            marginLeft: '36px'
          }}
        >
          {data?.currency}
        </Typography>
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            title: { text: '' },
            chart: {
              backgroundColor: 'transparent',
              height: 200
            },
            tooltip: {
              enabled: false
            },
            xAxis: {
              type: 'datetime',
              gridLineColor: '#FFFFFF1A',
              lineColor: '#FFFFFF1A',
              gridLineWidth: 1,
              tickLength: 0,
              labels: {
                style: {
                  color: '#ffffff80',
                  fontSize: '10px',
                  fontWeight: '500'
                }
              },
              plotLines: [
                {
                  color: 'rgba(255, 255, 255, 0.25)',
                  width: 3,
                  value: new Date().getTime()
                }
              ]
            },
            yAxis: {
              title: null,
              gridLineColor: '#FFFFFF1A',
              lineColor: '#FFFFFF1A',
              tickAmount: 5,
              labels: {
                style: {
                  color: '#ffffff80',
                  fontSize: '10px',
                  fontWeight: '500'
                }
              },
              plotLines: [
                {
                  color: 'rgba(255, 184, 34, 0.15)',
                  width: 4,
                  value: sliderValue[0]
                }
              ]
            },
            legend: {
              enabled: false
            },
            series: [
              {
                data: data?.prices || []
              }
            ],
            plotOptions: {
              line: {
                zoneAxis: 'y',
                zones: [{
                  value: sliderValue[0],
                  color: '#FFB822'
                },
                {
                  color: '#DCDCDC'
                }]
              }
            },
            credits: {
              enabled: false
            },
            lang: {
              noData: i18n.t('chartsNoData'),
              loading: i18n.t('load')
            }
          }}
        />
      </Box>
      <Box>
        <Typography variant="body1" sx={{ color: 'text.primary', lineHeight: '20px' }}>
          {i18n.t('price')}
        </Typography>
        <Slider
          sx={{
            '& .MuiSlider-thumb': {
              color: '#FFB822'
            },
            '& .MuiSlider-rail': {
              background: `linear-gradient(to right, #FFB822, #FFB822 ${(sliderValue[0] * 100) / maxPrice}%, #FFFFFF1A ${(sliderValue[0] * 100) / maxPrice}%, #FFFFFF1A 100%)`,
              opacity: 1
            },
            '& .MuiSlider-valueLabel': {
              backgroundColor: 'unset',
              top: 0,
              color: 'text.primary',
              opacity: 0.5
            },
            '& .MuiSlider-markLabel': {
              color: 'text.primary',
              opacity: 0.5
            },
            '& .MuiSlider-mark': {
              backgroundColor: 'unset'
            }
          }}
          track="inverted"
          aria-labelledby="track-false-range-slider"
          defaultValue={[0.3]}
          value={sliderValue}
          min={0}
          step={0.01}
          max={maxPrice}
          disableSwap
          onChange={(event, value) => setSliderValue(value)}
        />
      </Box>
    </>
  );
};

TariffOptimizedMode.propTypes = {
  formData: PropTypes.instanceOf(Object),
  change: PropTypes.func.isRequired
};

export default TariffOptimizedMode;
