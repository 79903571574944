export const themeMui = {
  components: {
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#779B42'
        }
      }
    }
  },
  palette: {
    primary: {
      main: '#779B42',
      dark: '#fafafa'
    },
    secondary: {
      main: '#000',
      dark: '#000',
      lowTariff: '#1BBC9B',
      highTariff: '#F53920',
      stepColor: '#000',
      stepSubtitleColor: '#959BA2',
      blockBackgroundLight: '#EFF0EF'
    },
    text: {
      primary: '#000',
      secondary: '#fff'
    }
  },
  typography: {
    h2: {
      fontSize: 20,
      fontWeight: 600
    },
    h3: {
      fontSize: 18,
      fontWeight: 600
    },
    h4: {
      fontSize: 16,
      fontWeight: 400
    },
    body1: {
      fontSize: 14,
      fontWeight: 400
    },
    body2: {
      fontSize: 12,
      fontWeight: 400
    },
    fontFamily: ['Gotham, sans-serif'].join(',')
  }
};
